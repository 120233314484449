<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        <h1>{{ $t('Edit Page Section') }}</h1>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.pages.sections.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <from-request @submit="handleUpdate"
                              :page-section="section"
                              :errors="errors"
                />
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import FromRequest from "@/views/admin/page-sections/FromRequest";
import PageSection from "@/models/admin/PageSection";

export default {
    name: "page-sections-edit",
    components: {
        FromRequest,
        AdminDashboardLayout,
    },
    data() {
        return {
            section: {},
            errors: {},
        }
    },
    methods: {
        handleUpdate({name, content, order}) {
            this.section.name = name
            this.section.content = content
            this.section.order = order
            this.section.patch().catch(({response}) => {
                if (response.status === 422) {
                    this.errors = response.data.errors
                }
            })
            this.$store.commit('showSnackBar', this.$t('messages.success'))
            this.$router.push({name: 'admin.pages.sections.index'})
        },
        fetchSection(id) {
            PageSection
                .find(id)
                .then(section => this.section = section)
        },
    },
    created() {
        this.fetchSection(this.$route.params.id)
    },
}
</script>

<style lang="scss">
</style>
