<template>
    <v-form>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Section Name"
                    v-model="section.name"
                    :error="errors.hasOwnProperty('name')"
                    :error-messages="errors.hasOwnProperty('name') ? errors.name : ''"
                >
                </v-text-field>
            </v-col>
            <v-col sm="12" md="6">
                <v-select
                    :label="`Page`"
                    :items="pages"
                    item-text="text"
                    item-value="value"
                    v-model="section.page"
                    :error="errors.hasOwnProperty('page')"
                    :error-messages="errors.hasOwnProperty('page') ? errors.page : ''"
                />
            </v-col>
            <v-col sm="12" md="6">
                <v-text-field
                    label="Section Order"
                    v-model="section.order"
                    :error="errors.hasOwnProperty('order')"
                    :error-messages="errors.hasOwnProperty('order') ? errors.order : ''"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <text-editor
                    label="Content"
                    v-model="section.content"
                    :errors="errors.content"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    color="orange"
                    large
                    value="Save"
                    @click="handleSave"
                >
                    <span>{{ $t('Save') }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

import TextEditor from "@/components/app/form/TextEditor";
export default {
    name: "FromRequest",
    props: ['pageSection', 'errors'],
    components: {
        TextEditor,
    },
    data: function () {
        return {
            section: {
                name: '',
                page: 'terms',
                content: ``,
                order: 0,
            },
        }
    },
    computed: {
        pages() {
            return [
                {value: 'contact', text: 'Contact'},
                {value: 'terms', text: 'Terms and conditions',},
                {value: 'privacy', text: 'Privacy Policy',},
                {value: 'anti_fraud_policy', text: 'AML & Anti-Fraud Policy',},
                {value: 'standard-agreement-noodzly-noodzler', text: 'Standard Agreement between Noodzly and Noodzler',},
                {value: 'anti-slavery-trafficking', text: `Anti-slavery and Anti-trafficking statement ${new Date().getFullYear()}`,},
                {value: 'dmca-takedown-policy', text: 'DMCA Takedown Policy',},
                {value: 'streams', text: 'Streams Policy',},
            ]
        }
    },
    watch: {
        pageSection() {
            this.setSection()
        },
    },
    methods: {
        handleSave() {
            this.$emit('submit', this.section)
        },
        setSection() {
            if (!this.pageSection) {
                return
            }
            this.section = Object.assign(this.section, this.pageSection)
        }
    },
    created() {
        this.setSection()
    }
}
</script>

<style lang="scss">
</style>
