<template>
    <div class="tiptap-vuetify">
        <div class="d-flex justify-space-between align-center mb-2">
            <div>{{label}}</div>
            <v-switch
                class="mt-0"
                v-model="isHtml"
                label="HTML"
                color="orange"
                hide-details
            ></v-switch>
        </div>
        <v-textarea
            v-if="isHtml"
            name="input-7-1"
            filled
            label="Label"
            auto-grow
            v-model="content"
        ></v-textarea>
        <tiptap-vuetify v-else
                        v-model="content"
                        :extensions="extensions"
                        :toolbar-attributes="{ color: '#1A1A1A' }"
        ></tiptap-vuetify>
        <div>
            {{errors}}
        </div>
    </div>
</template>

<script>
import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Table,
    TableRow,
    TableCell,
    TableHeader,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
} from 'tiptap-vuetify'

export default {
    name: "TextEditor",
    props: {
        value: {},
        label: {
            type: String
        },
        errors: {
            type: Object,
        }
    },
    components: {
        TiptapVuetify
    },
    data() {
        return {
            content: '',
            isHtml: false,
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                Code,
                Table,
                TableRow,
                TableCell,
                TableHeader,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
        }
    },
    watch: {
        content() {
            this.$emit('input', this.content)
        },
        value() {
            this.content = this.value
        }
    }
}
</script>

<style scoped lang="scss">

</style>
